.banner.clearfix {
    margin-top: -4vh;
}

@media (max-width: 575.98px) { 

    .banner.clearfix {
        margin-top: 8vh;

    }
}

@media (min-width: 767.98px) { 

    .overlay-container.overlay-visible img {
      min-width: 100%;

    }
}